import React from 'react';
import { Helmet } from 'react-helmet';
import {
  Container,
  Row,
  Col,
  Button
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import VisitorHeader from '../common/visitor_header';
import UserHeader from '../common/user_header';
import PageTitle from '../common/page_title';
import BasicInfo from './basic_info';
import PhysicalInfo from './physical_info';
import StdInfo from './std_info';
import SemenInfo from './semen_info';
import RequestInfo from './request_info';
import Message from './message';
import PleaseLogin from './please_login';
import Footer from '../common/footer';
import Auth from '../../services/auth';
import ApiClient from '../../services/api_client';
import Util from '../../services/util';
import { Spinner } from '../common/spinner';
import { ja } from '../../locales/ja';

class DonorProfileView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      donor: {
        name: '',
        areas: [],
        methods: []
      },
      std: {},
      semen: {}
    }
  }

  componentWillMount = () => {
    this.setState({ loading: true });

    const { user_no } = this.props.match.params;
    let req = ApiClient.secureGetRequestTo(`/api/v1/donors/show_by_no/${ user_no }`);

    fetch(req).then((res) => {
      this.setState({ loading: false });

      if (!res.ok) {
        throw Error(`[GET Donor] ${res.status} ${res.statusText}`);
      }

      return res.json();
    }).then((json) => {
      const user = json.data;
      const donor_basic_info = user.donor_basic_info || {};
      const donor_physical_info = user.donor_physical_info || {};
      const donor_request = user.donor_request || {};
      const donor_std = user.donor_std || {};
      const donor_semen = user.donor_semen || {};
      const std_collected_on = new Date(donor_std.collected_on + 'T00:00:00');
      const semen_collected_on = new Date(donor_semen.collected_on + 'T00:00:00');
      const today = new Date();
      const last_year = new Date(today.getFullYear()-1, today.getMonth(), today.getDate());
      this.setState({
        donor: {
          name: user.name || '',
          count: user.count,
          last_activity_on: user.last_activity_on,
          age: donor_basic_info.birth_date ? Util.getAge(donor_basic_info.birth_date) : '---',
          areas: donor_basic_info.areas || [],
          college_type: donor_basic_info.college_type || '',
          major_type: donor_basic_info.major_type || '',
          college: donor_basic_info.college || '',
          major: donor_basic_info.major || '',
          marriage: donor_basic_info.marriage || '',
          son: donor_basic_info.son || 0,
          daughter: donor_basic_info.daughter || 0,
          comments: donor_basic_info.comments || '',
          blood_type: donor_physical_info.blood_type || '---',
          blood_rh: donor_physical_info.blood_rh || '',
          height: donor_physical_info.height || '---',
          weight: donor_physical_info.weight || '---',
          body_figure: donor_physical_info.body_figure || '',
          methods: donor_request.methods || [],
          gratuity: donor_request.gratuity || '---'
        },
        std: {
          certified: donor_std.status === 'CERTIFIED' && std_collected_on > last_year,
          collected_on: donor_std.collected_on,
          total: donor_std.total
        },
        semen: {
          certified: donor_semen.status === 'CERTIFIED' && semen_collected_on > last_year,
          collected_on: donor_semen.collected_on,
          total: donor_semen.total,
          volume: donor_semen.volume,
          concentration: donor_semen.concentration,
          total_count: donor_semen.total_count,
          motility: donor_semen.motility,
          morphology: donor_semen.morphology
        }
      });
    }).catch((err) => {
      console.error(err);
    });
  }

  goBack = (e) => {
    this.props.history.goBack();
  }

  postToX = () => {
    this.setState({ loading: true });

    const { user_no } = this.props.match.params;
    const req = ApiClient.secureGetRequestTo(
      `/api/v1/admin/users/${user_no}/x_post_for_certified_donor`,
      {}
    );

    fetch(req).then((res) => {
      this.setState({ loading: false });

      if (!res.ok) {
        throw new Error(`[GET x post text] ${res.status} ${res.statusText}`);
      }

      return res.json();
    }).then((json) => {
      if (json.message) {
        toast.error(json.message);
      } else {
        const data = json.data;
        try {
          navigator.clipboard.writeText(data.post_content);
          toast.success('コピーしました。' + data.post_content);
        } catch (err) {
          toast.error('コピーに失敗しました。');
        }
      }
    }).catch((err) => {
      toast.error('XへのPOSTに失敗しました。時間をおいてお試しください。');
    });
  }

  render() {
    let headerSet = null;
    if (Auth.isLoggedIn()) {
      headerSet = <UserHeader />
    } else {
      headerSet = <VisitorHeader />;
    }

    const { user_no } = this.props.match.params;
    const { donor, std, semen } = this.state;

    return (
      <div>
        <Spinner isLoading={ this.state.loading } />
        <Helmet>
          <title>Ton Bébé | ドナープロフィール</title>
          <meta name='description' content={ `${ ja['site_description'] }このページは ${ donor.name.split('').join('.') + '.' } さんのプロフィールです。` } />
        </Helmet>
        { headerSet }
        <PageTitle title={ `${ donor.name.split('').join('.') + '.' } (No. ${ user_no })` } />
        <Container className='profile-content-wrapper'>
          <Row className='items-middle'>
            <Col xs={ 12 }>
              <Button
                variant=''
                className='btn-link-dark btn btn-lg'
                onClick={ this.goBack }
              >&lt;&lt;&nbsp;一覧に戻る</Button>
              {
                Auth.isAdmin() &&
                <Button
                  variant=''
                  className='btn-link-dark btn btn-lg'
                  onClick={ this.postToX }
                >XにPost</Button>
              }
            </Col>
          </Row>
        </Container>
        <BasicInfo donor={ donor } />
        <PhysicalInfo donor={ donor } />
        <StdInfo std={ std }/>
        <SemenInfo semen={ semen }/>
        <RequestInfo donor={ donor } />
        { Auth.isDonee() && <Message donor_no={ user_no }/> }
        { !Auth.isLoggedIn() && <PleaseLogin /> }
        <Footer />
      </div>
    )
  }
}

export default DonorProfileView;
